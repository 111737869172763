import React, { ReactElement } from "react"
import DemoCTA from "../../components/DemoCTA/DemoCTA"
import Discover from "../../components/HomePage/Discover"
import { MediaRender } from "../../components/HomePage/NewsAndUpdates"
import {
  DownloadSample,
  ProductFeature,
  ProductView,
  WatchDemo,
} from "../../components/Product/ProductComponents"
import Seo from "../../components/seo"
import { productsList } from "../../constants/products"
import DefaultLayout from "../../templates/DefaultLayout"

interface Props {}

const features = [
  "Quickly produce credible offers backed by defendable breakdowns.",
  "Boost client confidence with detailed advice on the value of their claim.",
  "Quickly advice clients of the total cost or benefit of a settlement offer.",
  "Slash the time required for junior lawyers to learn how to assess and advise on damages.",
  "Easily produce multiple assessments for each claim.",
  "Enhance client communication. Use the Damages Calculator to illustrate the impact of different risks on the value of the claim.",
  "Support offers of compromise with detailed breakdowns to maximise the likelihood of costs consequences.",
  "Use the Damages Calculator to reverse-engineer an opponents numbers to zero in on the real issues in settlement negotiations.",
  "Challenge the credibility of opponent valuations.",
]
export default function DamagesCalculator(): ReactElement {
  return (
    <DefaultLayout>
      <Seo
        title="Damages Calculator"
        description="The lawlite Damages Calculator automates the number crunching of calculating damages.  Finally, lawyers are free up to focus on the strategy, advocacy, and maximizing client experience. Use the Damages Calculator to reverse-engineer an opponents numbers to zero in on the real issues in settlement negotiations."
      />
      <div className="flex text-br-primary-blue flex-col">
        <ProductView {...productsList[1]} />
        <WatchDemo video={"https://www.youtube.com/embed/zbCY_uYTf6E"} />
        <DownloadSample
          link={"/sample-schedule-of-damages.docx"}
          text={"Download Sample Schedule of Damages"}
        />
        <DemoCTA />
        <Discover hideTitle />
        <ProductFeature
          title={"You make the Decisions. We Crunch the Numbers."}
        >
          <p>
            The LawLite Damages Calculator automates the number crunching of
            calculating damages.
          </p>
          <p className="my-6">
            Finally, lawyers are free up to focus on the strategy, advocacy, and
            maximizing client experience.
          </p>
          <ul className="list-disc lg:ml-16 ml-8">
            {features.map(feature => (
              <li key={feature} className="mt-6">
                {feature}
              </li>
            ))}
          </ul>
        </ProductFeature>
      </div>
    </DefaultLayout>
  )
}
